<template>
  <div class="page">
    <!-- Banner -->
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" v-if="formuleCommande">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>

      <swiper-slide
        v-if="formuleCommande.mediaBandeau.contentUrl !== undefined"
        class="swiper-slide"
        :style="{ backgroundImage: 'url(' + baseURL+formuleCommande.mediaBandeau.contentUrl + ')' }"
      >
        <div class="content-slider passerCommande" v-html="formuleCommande.textBandeau"></div>
      </swiper-slide>
    </swiper>

    <!-- Feature Summary -->
    <div class="bloc center fonctionnement">
      <vs-row vs-w="12">
        <vs-col
          vs-justify="center" vs-align="center"
          vs-lg="3" vs-sm="6" vs-xs="12"
          class="space-10"
        >
          <img src="@/assets/images/icones/agenda-white.png">
          <p>Mise en place d’un agenda<br> partagé</p>
        </vs-col>

        <vs-col
          vs-justify="center" vs-align="center"
          vs-lg="3" vs-sm="6" vs-xs="12"
          class="space-10"
        >
          <img src="@/assets/images/icones/reseau-white.png">
          <p>Présentation des points forts <br>du réseau et du négociateur</p>
        </vs-col>

        <vs-col
          vs-justify="center" vs-align="center"
          vs-lg="3" vs-sm="6" vs-xs="12"
          class="space-10"
        >
          <img src="@/assets/images/icones/notif-white.png">
          <p>RDV notifié par mail <br>et par SMS</p>
        </vs-col>

        <vs-col
          vs-justify="center" vs-align="center"
          vs-lg="3" vs-sm="6" vs-xs="12"
          class="space-10"
        >
          <img src="@/assets/images/icones/suiv-white.png">
          <p>Visibilité et suivi en temps<br> réel de la commande</p>
        </vs-col>
      </vs-row>
    </div>

    <!-- Form -->
    <div class=" body">
      <!-- content -->
      <div class="center" v-if="formuleCommande !== null">
        <form-wizard
          ref="wizard"
          color="rgba(var(--vs-primary), 1)"
          :title="null" :subtitle="null"
          finishButtonText="Submit"
          @on-complete="sent()"
        >
          <tab-content title="Commande" class="mb-5" :before-change="valider" >

            <!-- Number of appointments Select -->
            <div class="bloc" >
              <h1>
                Nombre de rendez-vous <sup>*</sup>
                <span>Sélectionnez le nombre de rendez-vous souhaités</span>
              </h1>
              <vs-row vs-w="12" vs-justify="center">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="2" vs-sm="2" vs-xs="12"
                />

                <vs-col
                  v-for="item in formuleCommande.prix "
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="4" vs-sm="4" vs-xs="12"
                  class="space-10 checkBoxItem"
                  :key="item.prix"
                >
                  <vs-radio
                    v-if="debutant !== null"
                    v-model="nbRdv"
                    :vs-value="item"
                    :class="erreurs.nbRdv"
                    :disabled="true"
                    :checked="!nbrRdvDebutant"
                  >
                    <b>{{nbrRdvDebutant}}</b>
                    <span> à 0€ HT </span>
                  </vs-radio>

                  <vs-radio
                    v-else
                    v-model="nbRdv"
                    :vs-value="item"
                    :class="erreurs.nbRdv"
                    :disabled="!formuleCommande.choixNbRdv"
                    :checked="!formuleCommande.choixNbRdv"
                  >
                    <b>{{item.nb}}</b>
                    <span> à {{item.prix}}€ HT</span>
                  </vs-radio><br>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="2" vs-sm="2" vs-xs="12"
                />
              </vs-row>
            </div>

            <!-- Criteria -->
            <div class="bloc">
              <h1>
                Critères <sup>*</sup>
                <span>Sélectionnez vos critères</span>
              </h1>

              <!-- House -->
              <vs-row vs-w="12" vs-justify="center">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="2" vs-sm="4" vs-xs="12"
                  :class="['space-10', 'm-1', 'checkBoxItem', 'critere', erreurs.criteres]"
                >
                  <div class="label_checkbox">
                    <img src="@/assets/images/icones/maison.png">
                    <span>Maison</span>
                  </div>
                  <vs-checkbox class="m-2" vs-value="Maison" required v-model="criteres" />
                </vs-col>

                <!-- Ground -->
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="2" vs-sm="4" vs-xs="12"
                  :class="['space-10', 'm-1', 'checkBoxItem', 'critere', erreurs.criteres]"
                >
                  <div class="label_checkbox">
                    <img src="@/assets/images/icones/terrain.png">
                    <span>Terrain</span>
                  </div>
                  <vs-checkbox
                    v-model="criteres"
                    class="m-2"
                    vs-value="Terrain"
                    required="'required'"
                  />
                </vs-col>

                <!-- Apartment -->
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="2" vs-sm="4" vs-xs="12"
                  :class="['space-10', 'm-1', 'checkBoxItem', 'critere', erreurs.criteres]"
                >
                  <div class="label_checkbox">
                    <img src="@/assets/images/icones/appartement.png">
                    <span>Appartement</span>
                  </div>
                  <vs-checkbox
                    v-model="criteres"
                    class="m-2"
                    vs-value="Appartement"
                    required="'required'"
                  />
                </vs-col>

                <!-- Loft -->
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="2" vs-sm="4" vs-xs="12"
                  :class="['space-10', 'm-1', 'checkBoxItem', 'critere', erreurs.criteres]"
                >
                  <div class="label_checkbox">
                    <iconLoft />
                    <span>Loft</span>
                  </div>
                  <vs-checkbox
                    v-model="criteres"
                    class="m-2"
                    vs-value="Loft"
                    required="'required'"
                />
                </vs-col>
              </vs-row>
            </div>

            <!-- Postal Code Select -->
            <div class="bloc">
              <h1>
                Codes postaux <sup>*</sup>
                <span>Sélectionnez vos codes postaux</span>
              </h1>

              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />

                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="6" vs-sm="12" vs-xs="12"
                  class="space-10 checkBoxItem"
                >
                  <vue-tags-input
                    class="form-control vs-input vx-col w-full"
                    :class="erreurs.postalCodes"
                    v-model="tag"
                    :sort-search-results="true"
                    :tags="tags"
                    :autocomplete-items="autocompleteItems"
                    :add-only-from-autocomplete="true"
                    @tags-changed="update"
                    @before-adding-tag="add"
                    @before-deleting-tag="del"
                    placeholder="Ajouter un code postal"
                    required="'required'" /><br>
                </vs-col>

                <vs-input name="communeshidden" v-model="communeshidden" type="hidden" />

                <vs-input name="nbAnnonces" v-model="nbAnnonces" type="hidden" />

                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />
              </vs-row>

              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />

                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="6" vs-sm="12" vs-xs="12"
                  class="space-10 checkBoxItem"
                >
                  <b>Annonces disponibles</b>
                  <vs-progress :height="5" :percent="percentAnnonce" :color="percentAnnonceColor" />
                </vs-col>
              </vs-row>

              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="6" vs-sm="12" vs-xs="12"
                  class="space-10 checkBoxItem"
                >
                  *Seuls les codes postaux dans un rayon de 30km de votre secteur vont apparaître
                </vs-col>
              </vs-row>
            </div>

            <!-- Number exclusion -->
            <div class="bloc">
              <h1>Numéros à exclure
                <span>Quels numéros de téléphone souhaitez-vous exclure ?</span>
              </h1>
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />

                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="6" vs-sm="6" vs-xs="12"
                  class="space-10 checkBoxItem"
                >
                  <vs-input
                    v-model="num"
                    class="w-full"
                    name="num"
                    placeholder="*Séparez les numéros de téléphone avec des virgules"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />
              </vs-row>
            </div>

            <!-- Terms of use acceptation -->
            <div class="bloc">
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />

                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="6" vs-sm="6" vs-xs="12"
                  class="space-10 checkBoxItem"
                >
                  <vs-checkbox
                    v-model="conditions"
                    :class="[inline-flex, erreurs.CGU]"
                    name="conditions"
                    required="'required'"
                  >
                    J'ai lu et j'approuve
                    les conditions d'utilisation et de confidentialité. <sup>*</sup>
                  </vs-checkbox>
                  <p>
                    <vs-icon
                      :color="color"
                      :icon="icon" :icon-pack="iconPack"
                      @click="popupActive=true"
                      class="iconHover cge"
                    />
                  </p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center" vs-align="center"
                  vs-lg="3" vs-sm="3" vs-xs="12"
                />
              </vs-row>

              <vs-popup
                :active.sync="popupActive"
                :background-color-popup="colorx"
                background-color="color"
                class="grande"
                title="Conditions d’utilisation"
              >
                <cgu />
                <vs-button class="mr-3 mb-2 float-right" @click="acceptCGU()">Je valide</vs-button>
              </vs-popup>

              <vs-popup
                :active.sync="popupLimit"
                :background-color-popup="colorx"
                background-color="color"
                title="Avertissement"
              >
                <div v-html="limitError" /><br><br>

                <vs-button
                  v-if="bonus"
                  class="mr-3 mb-2 float-right"
                  @click="addBonus()"
                >
                  Ajouter des Codes postaux
                </vs-button>

                <vs-button
                  v-if="showBtn"
                  class="mr-3 mb-2"
                  type="border"
                  @click="showDetails()"
                >
                  Je confirme ma commande
                </vs-button>
              </vs-popup>
            </div>
          </tab-content>

          <!-- Summary -->
          <tab-content title="Confirmation"  class="mb-5" :before-change="Confirmer" >
            <div class="checkBoxItem">
              <b>Formule :</b> {{type}}<br>
              <b>Nombre de rendez-vous: </b>{{this.nbRdv.nb}}<br>
              <b>Critères: </b>{{criteres}}<br>
              <b>Prix HT: </b>{{price}}€<br>
              <b>Prix TC (20%): </b>{{priceTc}}€<br>
              <br><br>
              Pour passer au paiement, veuillez confimer votre commande.<br>
              Après la confirmation,
              aucun changement dans la commande ne sera pris en considération.
              <br><br>

              <vs-button class="mr-3 mb-2"  @click="sent()">Confirmer</vs-button>
            </div>
          </tab-content>

          <!-- Go to pay -->
          <tab-content title="Paiement" class="mb-5" >
            <form :action="paymentUrl" method="POST" id="systempay-form">
              <input
                v-for="[key, value] of Object.entries(paymentFields)"
                type="hidden"
                :key="key"
                :name="key"
                :value="value"
              >

              <input
                class="payment"
                type="submit"
                value="Cliquez pour être redirigé vers le site de paiement"
              >
            </form>
          </tab-content>

          <!-- Next page -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-right">
              <vs-button
                v-if="props.activeTabIndex == 0"
                @click.native="props.nextTab()"
                class="wizard-footer-right"
                :style="props.fillButtonStyle"
              >
                Suivant
              </vs-button>

              <vs-button
                v-else-if="props.activeTabIndex == 1"
                @click.native="props.nextTab()"
                class="wizard-footer-right disabled"
                :style="props.fillButtonStyle"
                :disabled="disabledBtn"
                >
                  Suivant
                </vs-button>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import nego from '@/assets/images/icones/man.png';
import commande from '@/assets/images/icones/nouveau.png';
import image from '@/assets/images/pages/formule.png';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import moment from 'moment';
import vSelect from 'vue-select';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import iconLoft from '@/components/icons/loft';
import router from '../../../router';
import cgu from '../../components/CGU.vue';
import store from '../../../store/store.js';
import axios from '../../../axios.js';

Vue.use(VeeValidate);
const { user } = store.state.AppActiveUser;
const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  components: {
    FormWizard,
    TabContent,
    VueTagsInput,
    'v-select': vSelect,
    swiper,
    swiperSlide,
    cgu,
    iconLoft,
  },
  data() {
    return {
      baseURL,
      formuleCommande: null,
      promotionCommande: null,
      percentAnnonceColor: 'danger',
      percentAnnonce: 0,
      disabledBtn: true,
      typeLoad: 'point',
      activeLoading: false,
      nbAnnonces: 0,
      unpaid: false,
      nbrRdvDebutant: 0,
      erreurs: {
        nbRdv: '',
        criteres: '',
        postalCodes: '',
        CGU: '',
      },
      populationLimitPercentage: 1 + (this.$tolerancePopulation / 100),
      tag: '',
      tags: [],
      autocompleteItems: [],
      debounce: null,
      text: '',
      prix: '',
      info: null,
      nom: this.$store.state.AppActiveUser.user.firstName,
      trigramme: this.$store.state.AppActiveUser.user.trigramme,
      prenom: this.$store.state.AppActiveUser.user.lastName,
      icon: 'info',
      iconPack: 'material-icons',
      tel: this.$store.state.AppActiveUser.user.telephone,
      email: this.$store.state.AppActiveUser.user.email,
      nbCommandes: '',
      checked: '',
      conditions: '',
      formuleCmd: [],
      criteres: [],
      showBtn: false,
      bonus: false,
      fonctionnement: '',
      population: 0,
      debutant: null,
      num: '',
      nbRdv: { prix: 0, nb: 0 },
      nego,
      colorx: '#ffffff',
      color: '#ed1451',
      popupActive: false,
      popupLimit: false,
      popupUnpaid: false,
      limitError: '',
      communeshidden: '',
      commandesNegoEC: 0,
      commandesNegoEA: 0,
      limite: false,
      formule: {
        img: image,
      },
      commande,
      price: 0,
      tva: 20,
      priceTc: 0,
      detailsCommandePopup: false,
      valide: false,
      confirm: false,
      orderPayment: 0,
      paymentUrl: '',
      paymentFields: [],

    };
  },
  methods: {
    getFormule() {
      let url = '';
      if (this.$route.query.promotion !== undefined) {
        url = `/api/sous_formule_commandes?id=${this.$route.query.promotion}`;
      } else {
        url = `/api/formule_commandes?libelle=${this.$route.query.type}`;
      }
      axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          this.formuleCommande = response.data[0];
          this.nbRdv = response.data[0].prix[0];
        }
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
    Confirmer() {
      return new Promise((resolve, reject) => {
        if (this.confirm == false) {
          reject('Veuillez confirmer votre commande');
          this.$vs.notify({
            title: 'Erreur',
            text: 'Veuillez confirmer votre commande',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            time: 10000,
          });
        } else {
          axios.get(`/api/payment/do/${this.orderPayment}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }).then((response) => {
            this.paymentUrl = response.data.paymentUrl;
            this.paymentFields = response.data.fields;
            if (this.formuleCommande.free === true) {
              axios.get(`/api/payment/verification/free-order/${this.orderPayment}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
              }).then(
                router.push('/payment/return/success'),
              );
            }
          });
          resolve(true);
        }
      });
    },
    add(obj) {
      if (obj.tag.id && obj.tag.classes == '') {
        obj.addTag();
        this.communeshidden += `/ ${obj.tag.code}-${obj.tag.codePostal}`;
        this.nbAnnonces += parseInt(obj.tag.nbAnnonces);
        this.percentAnnonce = (this.nbAnnonces / this.$annoncesMax) * 100;
        if (parseInt(this.nbAnnonces) >= parseInt(this.$annoncesMax)) {
          this.percentAnnonceColor = 'success';
        } else if (parseInt(this.nbAnnonces) >= parseInt(this.$annoncesMax - 10)) {
          this.percentAnnonceColor = 'warning';
        } else {
          this.percentAnnonceColor = 'danger';
        }
        if (this.tags.length == 0 || parseInt(this.population) + parseInt(obj.tag.population) <= (this.$limitPopulation * this.populationLimitPercentage)) {
          this.population += parseInt(obj.tag.population);
        }
      } else {

      }
    },
    del(obj) {
      this.population -= parseInt(obj.tag.population);
      this.communeshidden = this.communeshidden.replace(`/ ${obj.tag.code}-${obj.tag.codePostal}`, '');
      this.nbAnnonces -= parseInt(obj.tag.nbAnnonces);

      obj.deleteTag();
    },
    update(newTags) {
      this.autocompleteItems = [];
      this.tags = newTags;
    },
    initItems() {
      if (this.tag.length === 0) {
        this.activeLoading = false;
        this.$vs.loading.close();
        return;
      }
      this.activeLoading = true;
      this.$vs.loading({
        type: this.typeLoad,
      });
      const url = `/api/reservation/postal-code/search/${this.$store.state.AppActiveUser.user.id}/${this.tag}`;

      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then((response) => {
          this.autocompleteItems = response.data.map((a) => {
            if (this.communeshidden.includes(`${a.code}-`)) {
              LaClass = '';
            } else {
              var LaClass = ((parseInt(this.population) + parseInt(a.population) >= (this.$limitPopulation * this.populationLimitPercentage)) && this.tags.length > 0) ? 'color-info-inverse' : '';
            }
            if ((parseInt(a.nbCommandes) + parseInt(a.nbCommandesOld)) > parseInt(Math.trunc(a.population / 10000))) {
              LaClass = 'cursor-default color-warning-inverse';
            }
            if (a.isTooFar == true) LaClass = 'cursor-default color-danger-inverse';
            // return result.name;
            return {
              text: `${a.codePostal} ${a.name} (${a.population} habitants)`,
              id: a.id,
              name: a.name,
              codePostal: a.codePostal,
              population: a.population,
              code: a.code,
              isFull: a.isFull,
              nbAnnonces: a.nbAnnonces,
              classes: LaClass,
            };
          });
          this.autocompleteItems = this.autocompleteItems.sort((a, b) => { // sort using this.orderBy
            if (a.classes < b.classes) return -1;
            if (a.classes > b.classes) return 1;
            return 0;
          });
          this.activeLoading = false;
          this.$vs.loading.close();
        });
      }, 600);
    },
    acceptCGU() {
      this.conditions = true;
      this.popupActive = false;
      this.nextTab();
    },
    showDetails() {
      this.popupLimit = false;
      this.popupActive = false;
      this.valide = true;
      this.$refs.wizard.nextTab();
    },
    valider() {
      let { limite } = this;
      if (!this.formuleCommande.free) {
        this.priceTc = parseInt(this.nbRdv.prix) + (parseInt(this.nbRdv.prix) * parseInt(this.tva)) / 100;
      } else {
        this.priceTc = 0;
      }
      this.price = this.nbRdv.prix;
      // négociateur en état d'impayé ou suspendu
      if (this.unpaid) {
        this.popupLimit = true;
        limite = true;
      } else {
        // tous les champs doivent êtres remplis
        let erreur = false;
        if (this.criteres.length == '0') {
          this.erreurs.criteres = ' error';
          erreur = true;
        } else {
          this.erreurs.criteres = ' ';
        }
        if (this.formuleCommande.choixNbRdv) {
          if (this.nbRdv.nb == 0) {
            this.erreurs.nbRdv = ' error';
            erreur = true;
          } else {
            this.erreurs.nbRdv = ' ';
          }
        } else {
          if (this.debutant) {
            this.nbRdv.nb = this.nbrRdvDebutant;
          } else {
            this.nbRdv.nb = this.formuleCommande.prix[0].nb;
          }
          this.priceTc = parseInt(this.formuleCommande.prix[0].prix) + (parseInt(this.formuleCommande.prix[0].prix) * parseInt(this.tva)) / 100;
        }
        if (this.communeshidden.length == '0') {
          this.erreurs.postalCodes = ' error';
          erreur = true;
        } else {
          this.erreurs.postalCodes = ' ';
        }
        if (this.conditions == false) {
          erreur = true;
          this.erreurs.CGU = ' error';
        } else {
          this.erreurs.CGU = ' ';
        }

        // Total annonces, si il n'y a pas assez  d'annonces , on ajoute un bonus une seule fois
        if (this.nbAnnonces < this.$annoncesMax) {
          if (this.valide == false) {
            limite = true;
            this.limitError = 'Nous n’avons pas actuellement assez d’annonces pour votre sélection de code postaux!<br>';
            if (this.$limitPopulation < this.$limitPopulationBonus) {
              this.limitError += "Nous vous offrons la possibilité de choisir d'autres codes postaux.<br>"
                  + 'Sinon, ';
              this.bonus = true;
            } else this.bonus = false;
            this.limitError += 'Vous pouvez modifier votre sélection ou réessayer plus tard ou confirmer votre commande avec ces codes postaux. <br>';
            this.showBtn = true;
          }
        }
        if (this.commandesNegoEC + this.commandesNegoEA >= this.$commandesMax) {
          // nombre de commandes autorisées par négociateur
          limite = true;
          this.limitError = '<p>Vous avez dépassé le nombre limite des commandes. \n <br>';
          this.limitError += `Vous avez ${this.commandesNegoEA} commandes en attente et ${this.commandesNegoEC} commandes en cours.</p>`;
          this.bonus = false;
          this.showBtn = false;
        }
        return new Promise((resolve, reject) => {
          if (erreur == true) {
            this.$vs.notify({
              title: 'Erreur',
              text: 'Veuillez remplir les champs obligatoires',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              time: 10000,
            });
            reject('correct all values');
          } else if (limite == false) {
            resolve(true);
          } else {
            this.popupLimit = true;
            reject('correct all values');
          }
        });
      }
    },
    addBonus() {
      this.$limitPopulation = 20000;
      this.popupLimit = false;
    },
    sent() {
      const dateRef = Date.now();
      const date = moment().format('MM/DD/YYYY  h:mm:ss a');
      const data = {
        trigramme: this.trigramme,
        codesInsee: this.communeshidden,
        criteres: this.criteres,
        numeros: this.num,
        dateCreation: date,
        nbRdv: parseInt(this.nbRdv.nb),
        nbRdsRecreditesCommandes: parseInt(this.nbRdv.nb),
        nbRdvRestants: parseInt(this.nbRdv.nb),
        reference: this.trigramme + dateRef,
        statut: 'EP',
        mandatary: `/api/mandataries/${this.$store.state.AppActiveUser.user.id}`,
        reseau: user.network,
      };
      if (this.promotion !== null && this.promotion !== undefined) {
        data.offreCommande = `/api/sous_formule_commandes/${this.promotion}`;
        data.formule = this.formuleCommande.formuleCommande.libelle;
      } else {
        data.formule = this.formuleCommande.libelle;
      }
      axios.post('/api/commandes', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.status == 201) {
          const dataPayment = {
            customer: `/api/mandataries/${this.$store.state.AppActiveUser.user.id}`,
            isConfirmed: true,
            commande: `/api/commandes/${response.data.id}`,
          };
          axios.post('/api/payment_orders', dataPayment, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }).then((responsePayment) => {
            this.orderPayment = responsePayment.data.id;
            const invoiceData = {
              paymentOrder: `/api/payment_orders/${responsePayment.data.id}`,
              dutyFreeAmount: '0',
              total: (this.price).toString(),
              commande: `/api/commandes/${response.data.id}`,
            };
            axios.post('/api/invoices', invoiceData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            });
          });
          this.disabledBtn = false,
          this.$vs.notify({
            title: 'Succès',
            text: 'Votre commande a été confirmée...',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 2000,
          }, // router.push('/historique?id='+response.data.id)
          ),
          this.popupLimit = false;
          this.confirm = true;
        }
      }).catch((error) => {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 4000,
        });
      });
    },
    setValues() {
      axios.get(`/api/commandes?trigramme=${this.$store.state.AppActiveUser.user.trigramme}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        this.nbCommandes = response.data.length;
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
    checkDebutant() {
      this.activeLoading = true;
      this.$vs.loading({
        type: 'point',
      });
      axios.get(`/api/commandes/check_offre_debutant?trigramme=${this.$store.state.AppActiveUser.user.trigramme}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          if (this.type == 'Debutant') {
            if ((new Date() < new Date(response.data.dateFin)) && (response.data.dateCommande == null)) {
              const date = moment(String(response.data.dateFin)).format('MM/DD/YYYY');
              this.dateFin = date;
              this.nbRdv.nb = response.data.nbRdv;
              this.debutant = response.data.id;
              this.nbrRdvDebutant = response.data.nbRdv;
            }
          }
          this.$vs.loading.close();
        }
      });
    },
    getCommandes() {
      axios.get(`/api/commandes?trigramme=${this.$store.state.AppActiveUser.user.trigramme}&statut=EC`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        this.commandesNegoEC = response.data.length;
      }).catch((error) => {
        this.$toasted.show(error).goAway(1500);
      });
      axios.get(`/api/commandes?trigramme=${this.$store.state.AppActiveUser.user.trigramme}&statut=EA`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        this.commandesNegoEA += response.data.length;
      }).catch((error) => {
        this.$toasted.show(error).goAway(1500);
      });
    },
    checkImpaid() {
      axios.get(`/api/mandataries/etat/${user.email}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data.data != null) {
          if (response.data.data.suspended == true) {
            this.unpaid = true;
            this.limitError = 'Votre compte est suspendu!<br>'
            + 'Veuillez vous rapprocher du service facturation';
          }
          if (response.data.data.unpaid == true) {
            this.unpaid = true;
            this.limitError = "Vous êtes en état d'impayé!<br>"
            + 'Veuillez vous rapprocher du service facturation';
          }
        }
      });
    },
  },
  created() {
    this.getFormule();
    this.checkDebutant();
    this.setValues();
    this.getCommandes();
    this.checkImpaid();
  },
  watch: {
    tag: 'initItems',
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
    promotion() {
      return this.$route.query.promotion;
    },
    authHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      };
    },
  },
};
</script>
<style>
  h1 span {
    font-size: 12px;
    display: block;
    font-weight: 300;
    margin-top: 10px;
  }
  #systempay-form input[type="submit"]{
    padding: 30px;
    background: #7accad;
    border: none;
    font-size: 20px;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    border-radius: 13px;
    margin: 50px;
    cursor: pointer;
    color: #fff;
  }
  .error .ti-input, .error .checkbox_x.vs-checkbox, .error .vs-radio--borde {
    border: 1px solid red !important;
  }

  .critere {
    flex-direction: column;
  }

  .critere .checkBoxItem img {
    width: 80px;
  }

  .ti-autocomplete li {
    position: relative;
  }

  .checkBoxItem {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
  }
  .swiper-slide {
    background-size: cover !important;
    background-position-x: right;
  }

  .vue-tags-input .color-danger-inverse::after {
    background-color: #e03f3f;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: default;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 11.25px;
    font-weight: 700;
    height: 19px;
    line-height: 11.25px;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    position: absolute;
    content: "Hors secteur";
    top: 3px;
    right: 3px;
  }
  .vue-tags-input .color-danger-inverse{
    display: none;
  }
  .vue-tags-input .color-info-inverse::after {
    background-color: #00bcd4;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: default;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 11.25px;
    font-weight: 700;
    height: 19px;
    line-height: 11.25px;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    position: absolute;
    content: "Hors quotas";
    top: 3px;
    right: 3px;
  }

  .vue-tags-input .color-warning-inverse::after {
    background-color: #e0a73f;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: default;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 11.25px;
    font-weight: 700;
    height: 19px;
    line-height: 11.25px;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    position: absolute;
    content: "Complet";
    top: 3px;
    right: 3px;
  }

  .vue-tags-input {
    max-width: 100% !important;
    background-color: transparent !important;
    height: auto !important;
    line-height: normal !important;
  }

  .vue-tags-input .input {
    padding: 0 !important;
    border: none !important;
  }

  .vue-tags-input .tag {
    margin-left: 0 !important;
    font-size: 16px !important;
  }

  .new-tag-input-wrapper {
    width: 100%;
  }

  .vue-tags-input .color-danger-inverse, .vue-tags-input .color-info-inverse, .vue-tags-input .color-warning-inverse {
    position: relative;
    border-radius: 0;
    background-color: #90a4ae !important;
    padding-left: 0;
    cursor: default !important;
    color: #626262;
  }

  .cursor-default div {
    cursor: default !important;
  }

  .vue-tags-input .color-danger-inverse.selected-item, .vue-tags-input .color-danger-inverse.selected-item div,
  .vue-tags-input .color-info-inverse.selected-item, .vue-tags-input .color-info-inverse.selected-item div,
  .vue-tags-input .color-warning-inverse.selected-item, .vue-tags-input .color-warning-inverse.selected-item div {
    background-color: #90a4ae !important;
    cursor: default !important;
    color: #000000;
  }

  .new-tag-input-wrapper {
    margin: 5px 0 0 !important;
    padding: 0 !important;
    font-size: inherit !important;
  }

  .selected-item, .tag {
    background-color: #ed1651 !important;
  }

  .ti-input {
    padding: 6px !important;
    border-radius: 5px;
  }

  .ti-input input {
    font-size: 14px !important;
  }
</style>
