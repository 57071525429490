<template>
  <div vlass="cgu ">
  <h2>Conditions d’utilisation du service de prise de rendez-vous mandats proposé par Propriees- privees.com.</h2>
  <span class="date"> Mise à jour du 25 février 2020
</span>
  <p>Proprietes-privees.com propose à ses conseillers immobilier un service de prise de rendez-vous mandat dont les prestations sont réalisées par les équipes situées au siège de l’entreprise pour les packs de rendez-vous premium, et par des prestataires externes pouvant être situés en dehors du territoire français, pour les packs de rendez-vous standards.
  </p>
  <p>En souscrivant ce service, le conseiller immobilier membre du réseau Proprietes-privees.com choisit de déléguer tout ou partie de sa prospection téléphonique aux équipes indiquées ci-dessus et de respecter les conditions d’utilisation exprimées dans ce document.
  </p>
  <h4>Description du service : </h4>
  <p>L’équipe en charge de la prise de rendez-vous mandat contacte des particuliers ayant le projet de vendre leur bien ou ayant mis en vente leur bien afin de leur proposer un rendez-vous avec le conseiller immobilier. Lors de l’échange téléphonique, il sera vérifié qu’aucun mandat n’est déjà pris par un membre du réseau de Proprietes-privees.com. Par ailleurs, le conseiller immobilier est informé que :
  </p>
  <ul>
    <li>Le propriétaire vendeur a été informé que le rendez-vous est pris avec un professionnel de
      l’immobilier qui viendra seul - Il reste à la charge du conseiller immobilier de dérouler sa présentation commerciale afin d’obtenir un mandat avec ce client, lors du premier rendez-vous ou d’un rendez-vous suivant.
    </li>
  </ul>
  <h4>Les critères de ciblages :</h4>
  <p>Dans le cadre du service de prise de rendez-vous mandat, le conseiller immobilier aura la possibilité de définir ses propres critères de ciblage sur la base du secteur géographique, qui doit correspondre à celui habituellement travaillé par le conseiller, et le type de bien (maison / appartement / Terrain), tout critère supplémentaire serait refusé. </p>
  <h4>Engagement du conseiller :
  </h4>
  <p>Le conseiller souscrivant au service de prise de rendez-vous mandat s’engage à :
  </p>
  <ul>
    <li>Tenir à jour son agenda partagé afin que les rendez-vous puissent être positionnés
    </li>
    <li>Assurer l’ensemble des rendez-vous pris dans le respect de la loi et de la déontologie du métier
    </li>
    <li>Renseigner le résultat de chacun des rendez-vous sur l’interface dédiée à cet effet dans un délai de 48h maximum après le rendez-vous (ou par mail sur le pack premium à l’adresse pige@proprietes-privees.com)
    </li>
    <li> <b>Dans le cadre d’un pack Premium : n’avoir aucune action préalable au rendez-vous, un SMS de confirmation étant envoyé par l’équipe en charge de la prise de rendez-vous
    </b> </li>
    <li><b>Dans le cadre d’un Pack Standard : n’avoir d’autre action qu’une confirmation par SMS au client vendeur préalablement à la réalisation du rendez-vous
    </b> </li>
  </ul>
  <p>
    Tout manquement à ces engagements entrainerait la résiliation immédiate du service sans aucun droit à remboursement.

  </p>
  <h4>Conditions d’annulation et de remboursement : </h4>
  <p>
    Les rendez-vous sont pris par une équipe de conseillers formés à la prospection téléphonique sans engagement de délai pour l’obtention des rendez-vous car cela dépend du marché local.</p>

  <p>Dans le cas où le conseiller resterait plus d’un mois sans obtenir de nouveau rendez-vous, celui-ci pourrait demander le remboursement des rendez-vous restant à son crédit au tarif de 15€HT par rendez-vous standard et de 40€HT par rendez-vous premium (ce tarif étant revu de façon proportionnelle en cas d’offre spéciale). Le remboursement n’est pas valable sur les rendez-vous offerts.</p>
   <p> Dans le cas où un rendez-vous n’aurait pas été honoré par le client, il pourra être recrédité au conseiller étant entendu que cela ne vaut que si le conseiller n’a réalisé aucune autre démarche que l’envoi d’un SMS pour confirmer son rendez-vous au client. Tout autre action de la part du conseiller préalablement au rendez-vous annulerait tout droit à remplacement en cas d’annulation par le vendeur.

  </p>

  <h4>Droit de suite :</h4>
  <p>
    En cas de résiliation de son contrat de conseiller immobilier avec Proprietes-privees.com, le pack de rendez-vous éventuellement souscrit et non réalisé, sera considéré comme définitivement perdu sans aucune contre partie.

  </p>

  <h2>
    Conditions spécifiques à l’opération “Sans Limite” du 26 février 2020 au 30 juin 2020.
  </h2>
  <p>L’opération « Sans Limite » concerne les packs de rendez-vous mandats standards souscrits entre le 26 février 2020 et le 30 juin 2020 dans la limite de 100 packs mis en vente et dans la limite d’un seul pack souscrit par conseiller. Le conseiller immobilier bénéficiera dans le cadre de cette opération d’une réduction sur le prix du pack qui sera facturé 20€HT au lieu de 89€HT.
  </p>
  <p>Dans le cadre de cette formule, le conseiller pourra bénéficier de 2 nouveaux rendez-vous crédités à chaque fois qu’il signera et déclarera un mandat de vente sur un rendez-vous pris durant la période de l’opération à savoir du 26 février au 30 juin 2020. Pour en bénéficier, le conseiller devra indiquer le numéro de mandat dans l’interface de suivi de ses rendez-vous, dans un délai de 48h maximum après la publication du bien pris en mandat. Le crédit de 2 rendez-vous sera affecté dans un délai de 7 jours maximum après vérification de la validité et de la publication du mandat.
  </p>

  </div>
</template>
<script>
export default { name: 'cgu' };
</script>
<style>

  h2,h4,p,.date {clear: both; margin-bottom: 20px; }
</style>
